import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import { useBlogCategories } from '@helpers-blog'

export default () => {
  const categories = useBlogCategories()

  return (
    <>
      <Seo title='Domains' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle header='Domains' subheader='Choose from a growing collection of premium Handshake domains.' />
      </Stack>
      <Stack>
        <Main>
          <Divider />
          <Categories categories={categories} />
        </Main>
      </Stack>
    </>
  )
}
